import './Navbar.css'
import SignOutIcon from './SignOutIcon.js'
import { createUsername } from '../../functions/Functions'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { useState, useRef } from 'react'
import { useOrder } from '../../contexts/OrderContext'
import AddCustomerModal from '../AddCustomerModal/AddCustomerModal'
import AddCustomerForm from '../AddCustomerModal/AddCustomerForm'
import AddNewPartModal from '../AddNewPartModal/AddNewPartModal'
import AddNewPartForm from '../AddNewPartModal/AddNewPartForm'
import AddNewPickupModal from '../AddNewPickupModal/AddNewPickupModal'
import AddNewPickupForm from '../AddNewPickupModal/AddNewPickupForm'
import AddNewPickupPlaceModal from '../AddNewPickupPlaceModal/AddNewPickupPlaceModal'
import AddNewPickupPlaceForm from '../AddNewPickupPlaceModal/AddNewPickupPlaceForm'

const Navication = () => {
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    const { getOrders } = useOrder()

    const modalRef = useRef();
    const openModal = () => {
      modalRef.current.openModal()
  }
    const partsModalRef = useRef();
    const openPartsModal = () => {
      partsModalRef.current.openModal()
  }
    const pickupModalRef = useRef();
    const openPickupModal = () => {
      pickupModalRef.current.openModal()
  }
    const addPickupPlaceModalRef = useRef();
    const openAddPickupPlaceModal = () => {
      addPickupPlaceModalRef.current.openModal()
  }

    async function handleLogout() {
        setError('')

        try {
            await logout()
            history.push('/')
        } catch (error) {
            setError('Uloskirjautuminen epäonnistui')
        }
    }

    console.log(error)
    
    return ( 
        <>
        <AddCustomerModal ref={modalRef}>
            <AddCustomerForm props={modalRef}></AddCustomerForm>
        </AddCustomerModal>
        <AddNewPartModal ref={partsModalRef}>
            <AddNewPartForm props={partsModalRef}></AddNewPartForm>
        </AddNewPartModal>    
        <AddNewPickupModal ref={pickupModalRef}>
            <AddNewPickupForm props={pickupModalRef}></AddNewPickupForm>
        </AddNewPickupModal>
        <AddNewPickupPlaceModal ref={addPickupPlaceModalRef}>
            <AddNewPickupPlaceForm props={addPickupPlaceModalRef}></AddNewPickupPlaceForm>
        </AddNewPickupPlaceModal>
        <nav className="navbar">
            <div className="title"><Link className="links" to='/'>TILAUSLISTA</Link></div>
            <div className="navbar-links">
                <ul>
                    <li><Link className="links" to='/allorders'>KAIKKI TILAUKSET</Link></li>
                    <li><div className="links" onClick={openAddPickupPlaceModal}>LISÄÄ NOUTOPAIKKA</div></li>
                    <li><div className="links" onClick={openPickupModal}>ILMOITA NOUDOSTA</div></li>
                    <li><div className="links" onClick={openPartsModal}>LISÄÄ OSIA</div></li>
                    <li><div className="links" onClick={openModal}>LISÄÄ ASIAKAS</div></li>
                    <li><div className="links" >{createUsername(currentUser.email)}</div><span>TILAUKSIA: {getOrders.length}</span></li>
                    <li><div className="links" onClick={handleLogout} to='/'><SignOutIcon /></div></li>
                </ul>
            </div>

        </nav>
        </>
     );
}
 
export default Navication;