import { useRef, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import '../Login/Login.css'

export default function Login() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            setLoading(false);
            history.push('/');
        } catch (error) {
            setError('Kirjautuminen epäonnistui');
            setLoading(false);
            passwordRef.current.value = ''
        }
    }

    return (
        <div className="form-container">
                <div className="form-card">
                    <h2>KIRJAUDU</h2>
                        {error && <div className="alert">{error}</div>}
                        <form onSubmit={handleSubmit}>
                            <div>
                            <label htmlFor="email">SÄHKÖPOSTI</label>
                                <input id="email" type="email" ref={emailRef} required />
                                </div>
                            <label htmlFor="password">SALASANA</label>
                                <input id="password" type="password" ref={passwordRef} required />
                            <button disabled={loading} type="submit" className="form-button">{loading ? 'LATAA...' : 'KIRJAUDU'}</button>
                        </form>
                </div>
            </div>
    )
}
