import Navication from '../components/Navbar/Navbar';
import Grid from '../components/Grid/Grid';

export default function Dashboard() {
    return (
        <div>
            <Navication />
            <Grid />
        </div>
    )
}
