import { useState, forwardRef, useImperativeHandle } from 'react'
import './AddNewPickupPlaceModal.css'
import ReactDOM from 'react-dom'

const AddNewPickupPlaceModal = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            openModal: () => openModal(),
            closeModal: () => closeModal()
        }
    })

    const openModal = () => {
        setDisplay(true)
    }

    const closeModal = () => {
        setDisplay(false)
    }

if(display) {
    return ReactDOM.createPortal( 
        <div className="modal-wrapper">
            <div onClick={closeModal} className="modal-backdrop" />
                <div className="modal-box-pickup-place">
                    {props.children}
                </div>
            </div>
        , document.getElementById('modal-root'));
    }
    return null
});
export default AddNewPickupPlaceModal;