import { useState } from 'react'
import firebase from '../../firebase';
import 'firebase/firestore';
import './AddNewPartModal.css'

const AddNewPartForm = ({props}) => {
    const [partName, setPartName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const db = firebase.firestore();
            await db.collection('parts').add({
                name: partName.toUpperCase()
            })
            setPartName('');
            setIsLoading(false);
            props.current.closeModal()
        } catch (error) {
            setIsLoading(false);
        }

    }

    return (
        <form onSubmit={handleSubmit} className="inputform" autoComplete="off">
            <div className="form-header"><h3>LISÄÄ UUSI OSA</h3></div>
                <div className="input-field">
                    <input
                    required
                    value={partName} 
                    type="text" 
                    id="partName" 
                    onChange={(e) => setPartName(e.target.value)}></input>
                    <label htmlFor="partName">OSAN NIMI</label>
                </div>
                <button className="submit-button" type="submit">{isLoading ? 'LATAA...' : 'LISÄÄ OSA'}</button>
            </form>

    );
}
export default AddNewPartForm;