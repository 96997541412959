import { useState } from 'react'
import firebase from '../../firebase';
import 'firebase/firestore';

const AddCustomerForm = ({props}) => {
    const [customerName, setCustomerName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const db = firebase.firestore();
            await db.collection('customers').add({
                customerName: customerName.toUpperCase(),
                customerNumber: customerNumber
            })
            setCustomerName('');
            setCustomerNumber('');
            setIsLoading(false);
            props.current.closeModal()
        } catch (error) {
            setIsLoading(false);
        }

    }

    return (
        <form onSubmit={handleSubmit} className="inputform" autoComplete="off">
            <div className="form-header"><h3>LISÄÄ UUSI ASIAKAS</h3></div>
                <div className="input-field">
                    <input
                    required
                    value={customerName} 
                    type="text" 
                    id="cutomerName" 
                    onChange={(e) => setCustomerName(e.target.value)}></input>
                    <label htmlFor="cutomerName">ASIAKAS</label>
                </div>
                <div className="input-field">
                    <input required 
                    value={customerNumber} 
                    type="text" 
                    id="customerNumber" 
                    maxLength="6"
                    onChange={(e) => setCustomerNumber(e.target.value)} ></input>
                    <label htmlFor="customerNumber">ASIAKASNUMERO</label>
                </div>
                <button className="submit-button" type="submit">{isLoading ? 'LATAA...' : 'LISÄÄ ASIAKAS'}</button>
            </form>

    );
}
export default AddCustomerForm;