import { useState } from 'react'
import './Orders.css'
import ReadyIcon from './ReadyIcon';
import TrashIcon from './TrashIcon';
import {db} from '../../firebase'

export default function Orders({order}) {
    const [isCollapsed, setIsCollapsed] = useState();
    const style = { collapsed: { display: 'none'}, expanded: { display: 'flex' }}
    const orderStyle = { ready: {backgroundColor:'#A7D3A6'}, notReady: {backgroundColor: 'rgb(238, 238, 238)'}}

    const deletePickup = async () => {
        try {
            await db.collection('orders').doc(order.id).delete();
        } catch (error) {
            console.log(error.message)
        }
    }
    const setReady = async () => {
        try {
            await db.collection('orders').doc(order.id).update({
                completed: true
            })
        } catch (error) {
            console.log(error.message)
        }
    }

    const handleDelete = (plate) => {
    const r = window.confirm(`Haluatko poistaa tilauksen ${plate}?`)
        if (r === true) {
            deletePickup()
          } else {
              return
          }
    }

    return (
        <div className="orders-card">
            <div className="orders-header" style={order.completed ? orderStyle.ready : orderStyle.notReady} onClick={() => setIsCollapsed(!isCollapsed)}>
                <h2 className="license-plate">{order.licensePlate}</h2>
                <div><h3 className="customername">{order.customerName}</h3></div>
            </div>
            <div className="orders-card-content" style={isCollapsed ? style.expanded : style.collapsed}>
                <div className="orders-card-left">
                    <button 
                        className="ready-button" 
                        disabled={order.completed} 
                        onClick={setReady}
                        ><ReadyIcon order={order}/></button>
                </div>
                <div className="orders-card-center">{order.quickPicks.map((data, index) => {
                    return (
                        <div className="orders-card-parts" key={index}>- {data}</div>
                    )
                })}{order.writedParts.map((data, index) => {
                    return (
                        <div className="orders-card-parts" key={index}>- {data}</div>
                    )
                })}</div>
                <div className="orders-card-right"><div className="button-container" onClick={() => handleDelete(order.licensePlate)}><TrashIcon /></div></div>
            </div>
            <div className="orders-footer" style={isCollapsed ? style.expanded : style.collapsed}>
                <div className="created">{order.date}</div>
                <div className="creator">{order.creator}</div>
            </div>
        </div>
    )
}
