import React, { useContext, useState , useEffect} from 'react'
import {db} from '../firebase'
import { useAuth } from "../contexts/AuthContext";

const CustomerContext = React.createContext();

export function useCustomer() {
    return useContext(CustomerContext)
}

export function CustomerProvider({children}) {
    const [getCustomers, setGetCustomers] = useState([]);
    const { currentUser } = useAuth()

    useEffect(() => {
        if(currentUser)
        return db.collection('customers').onSnapshot((snapshot) => {
            const customerData = [];
            snapshot.forEach(doc => customerData.push(({ ...doc.data(), id: doc.id })))
            setGetCustomers(customerData);
        
        }, (error) => {
        })
    }, [currentUser]);
    
    const value = {
        getCustomers,
        setGetCustomers
    }

    return (
        <CustomerContext.Provider value={value}>
            { children }
        </CustomerContext.Provider>
    )
}