import { useState } from 'react'
import firebase from '../../firebase';
import 'firebase/firestore';
import './AddNewPickupPlaceModal.css'

const AddNewPickupPlaceForm = ({props}) => {
    const [places, setPlaces] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const db = firebase.firestore();
            await db.collection('places').add({
                name: places
            })
            setPlaces('');
            setIsLoading(false);
            props.current.closeModal()
        } catch (error) {
            setIsLoading(false);
        }

    }

    return (
        <form onSubmit={handleSubmit} className="inputform" autoComplete="off">
            <div className="form-header"><h3>LISÄÄ UUSI NOUTOPAIKKA</h3></div>
                <div className="input-field">
                    <input
                    required
                    value={places} 
                    type="text" 
                    id="placeName" 
                    onChange={(e) => setPlaces(e.target.value)}></input>
                    <label htmlFor="placeName">NOUTOPAIKAN NIMI</label>
                </div>
                <button className="submit-button" type="submit">{isLoading ? 'LATAA...' : 'LISÄÄ NOUTOPAIKKA'}</button>
            </form>

    );
}
export default AddNewPickupPlaceForm;