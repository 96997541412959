import { useState, useEffect } from 'react'
import firebase from '../../firebase';
import 'firebase/firestore';
import './AddNewPickupForm.css'
import WhatsAppIcon from '../Orders/WhatsAppIcon';

const AddNewPickupForm = ({props}) => {
    const [places, setPlaces] = useState('');
    const [pickupPlace, setPickupPlace] = useState('');
    const [partName, setPartName] = useState('');
    const [departure, setDeparture] = useState('');
    const [customerFor, setCustomerFor] = useState('');
    //const [isLoading, setIsLoading] = useState(false);

    const departures = [
        { id:1, departure: "8:00 lähtöön" },
        { id:2, departure: "10:30 lähtöön" },
        { id:3, departure: "13:30 lähtöön" },
        { id:4, departure: "Iltajakoon" }
    ]

    useEffect(() => {
        const db = firebase.firestore();
        return db.collection('places').onSnapshot((snapshot) => {
            const placesData = [];
            snapshot.forEach(doc => placesData.push(({ ...doc.data(), id: doc.id })))
            setPlaces(placesData);
        })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        const message = `${pickupPlace} nouto%0a${partName}%0a${departure} ${customerFor}%0a`
            window.open( 
                "whatsapp://send?text=" + message, '_self'
            );
            props.current.closeModal()
    }

    return (
        <form onSubmit={handleSubmit} className="inputform" autoComplete="off">
            <div className="form-header"><h3>ILMOITA NOUDOSTA</h3></div>
                <div className="input-field">
                    <input required 
                    placeholder="NOUTOPAIKKA"
                    className="data" 
                    id="datal" list="data" 
                    value={pickupPlace} onChange={(e) => setPickupPlace(e.target.value)} />
                        <datalist id="data">
                            {places ? places.map(place => (<option value={place.name} key={place.id}></option>)) : null}
                        </datalist>
                </div>
                <div className="input-field">
                    <input required 
                    value={partName} 
                    type="text" 
                    id="partname"
                    onChange={(e) => setPartName(e.target.value)} ></input>
                    <label htmlFor="partname">OSAN NIMI / REKKARI YMS</label>
                </div>
                <div className="input-field">
                    <input required
                    value={customerFor} 
                    type="text" 
                    id="customerFor"
                    onChange={(e) => setCustomerFor(e.target.value)} ></input>
                    <label htmlFor="customerFor">KENELLE MENOSSA?</label>
                </div>
                <div className="input-field">
                    <input 
                    placeholder="MIHIN LÄHTÖÖN MYYTY"
                    className="departure-data" 
                    id="departure-data" list="departuredata" 
                    value={departure} onChange={(e) => setDeparture(e.target.value)} />
                        <datalist id="departuredata">
                            {departures.map(depar => (<option value={depar.departure} key={depar.id}></option>))}
                        </datalist>
                </div>
                <button className="submit-button-pickup" type="submit"><WhatsAppIcon /></button>
            </form>

    );
}
export default AddNewPickupForm;