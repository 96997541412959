export const category = [
    { id:1, category: "HUOLTO-OSAT" },
    { id:2, category: "ALUSTA" },
    { id:3, category: "JOUSET/ISKUNVAIMENTIMET"},
    { id:4, category: "JARRUT" },
    { id:5, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { id:6, category: "KORI" },
    { id:7, category: "MOOTTORI" },
    { id:8, category: "PAKOPUTKISTO" },
    { id:9, category: "SÄHKÖ" },
    { id:10, category: "VOIMANSIIRTO" },
    
]

export const quickParts = [
    //HUOLTO-OSAT
    { name: "KAIKKI HUOLTO-OSAT", select: false, id: 101, category: "HUOLTO-OSAT" },
    { name: "ÖLJYNSUODATIN", select: false, id: 102, category: "HUOLTO-OSAT" },
    { name: "PROPPU", select: false, id: 103, category: "HUOLTO-OSAT" },
    { name: "ILMANSUODATIN", select: false, id: 104, category: "HUOLTO-OSAT" },
    { name: "SISÄILMASUODATIN", select: false, id: 105, category: "HUOLTO-OSAT" },
    { name: "POLTTOAINESUODATIN", select: false, id: 106, category: "HUOLTO-OSAT" },
    { name: "SYTYTYSTULPAT", select: false, id: 107, category: "HUOLTO-OSAT" },
    { name: "ÖLJYT", select: false, id: 108, category: "HUOLTO-OSAT" },
    //ALUSTA
    { name: "VASEN ETU ALATUKIVARSI", select: false, id: 201, category: "ALUSTA" },
    { name: "OIKEA ETU ALATUKIVARSI", select: false, id: 202, category: "ALUSTA" },
    { name: "TUKIVARENHELA", select: false, id: 203, category: "ALUSTA" },
    { name: "TUKIVARENHELA TAKIMMAINEN", select: false, id: 204, category: "ALUSTA" },
    { name: "VASEN ALAPALLONIVEL", select: false, id: 205, category: "ALUSTA" },
    { name: "OIKEA ALAPALLONIVEL", select: false, id: 206, category: "ALUSTA" },
    { name: "VASEN VAKAAJANTANKO", select: false, id: 207, category: "ALUSTA" },
    { name: "OIKEA VAKAAJANTANKO", select: false, id: 208, category: "ALUSTA" },
    { name: "VAKAAJANHELAT", select: false, id: 209, category: "ALUSTA" },
    { name: "VASEN RAIDETANKOPÄÄ", select: false, id: 210, category: "ALUSTA" },
    { name: "OIKEA RAIDETANKOPÄÄ", select: false, id: 211, category: "ALUSTA" },
    { name: "AKSIAALINIVEL", select: false, id: 212, category: "ALUSTA" },
    { name: "SUOJAKUMI AKSIAALINIVEL", select: false, id: 213, category: "ALUSTA" },
    { name: "OHJAUSVAIHDE", select: false, id: 214, category: "ALUSTA" },
    { name: "VASEN TAKATUKIVARSI", select: false, id: 215, category: "ALUSTA" },
    { name: "OIKEA TAKATUKIVARSI", select: false, id: 216, category: "ALUSTA" },
    { name: "VASEN TAKA POIKITTAISTUKIVARSI", select: false, id: 217, category: "ALUSTA" },
    { name: "OIKEA TAKA POIKITTAISTUKIVARSI", select: false, id: 218, category: "ALUSTA" },
    { name: "VASEN TAKA PITKITTÄISTUKIVARSI", select: false, id: 219, category: "ALUSTA" },
    { name: "OIKEA TAKA PITKITTÄISTUKIVARSI", select: false, id: 220, category: "ALUSTA" },
    { name: "VASEN TAKAVAKAAJANTANKO", select: false, id: 221, category: "ALUSTA" },
    { name: "OIKEA TAKAVAKAAJANTANKO", select: false, id: 222, category: "ALUSTA" },
    { name: "ETUPYÖRÄNLAAKERI", select: false, id: 223, category: "ALUSTA" },
    { name: "TAKAPYÖRÄNLAAKERI", select: false, id: 224, category: "ALUSTA" },
    //JOUSET/ISKUNVAIMENTIMET
    { name: "ETUJOUSET", select: false, id: 301, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "ETUJOUSI", select: false, id: 302, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "ETUISKUNVAIMENTIMET", select: false, id: 303, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "VASEN ETUISKUNVAIMENNIN", select: false, id: 304, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "OIKEA ETUISKUNVAIMENNIN", select: false, id: 305, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "ISKUNVAIMENTIMEN YLÄPÄÄN LAAKERI", select: false, id: 306, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "ISKUNVAIMENTIMEN YLÄPÄÄN TUKI", select: false, id: 307, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "JOUSIJALAN KORJAUSSARJA", select: false, id: 308, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "ISKUNVAIMENTIMEN SUOJAKUMISARJA", select: false, id: 309, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "TAKAJOUSET", select: false, id: 310, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "TAKAJOUSI", select: false, id: 311, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "TAKAISKUNVAIMENTIMET", select: false, id: 312, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "VASEN TAKAISKUNVAIMENNIN", select: false, id: 313, category: "JOUSET/ISKUNVAIMENTIMET" },
    { name: "OIKEA TAKAISKUNVAIMENNIN", select: false, id: 314, category: "JOUSET/ISKUNVAIMENTIMET" },
    //JARRUT
    { name: "ETUJARRUPALAT", select: false, id: 401, category: "JARRUT" },
    { name: "ETUJARRULEVYT", select: false, id: 402, category: "JARRUT" },
    { name: "ETUJARRUPALOJEN ASENNUSSARJA", select: false, id: 403, category: "JARRUT" },
    { name: "ETU KULUMISENILMAISIN", select: false, id: 404, category: "JARRUT" },
    { name: "VASEN ETUJARRUSATULA", select: false, id: 405, category: "JARRUT" },
    { name: "OIKEA ETUJARRUSATULA", select: false, id: 406, category: "JARRUT" },
    { name: "ETUJARRUSATULAN LIUKUTAPPISARJA", select: false, id: 407, category: "JARRUT" },
    { name: "ETUJARRULETKUT", select: false, id: 408, category: "JARRUT" },
    { name: "TAKAJARRUPALAT", select: false, id: 409, category: "JARRUT" },
    { name: "TAKAJARRULEVYT", select: false, id: 410, category: "JARRUT" },
    { name: "TAKAJARRUPALOJEN ASENNUSSARJA", select: false, id: 411, category: "JARRUT" },
    { name: "TAKA KULUMISENILMAISIN", select: false, id: 412, category: "JARRUT" },
    { name: "VASEN TAKAJARRUSATULA", select: false, id: 413, category: "JARRUT" },
    { name: "OIKEA TAKAJARRUSATULA", select: false, id: 414, category: "JARRUT" },
    { name: "TAKAJARRUSATULAN LIUKUTAPPISARJA", select: false, id: 415, category: "JARRUT" },
    { name: "TAKAJARRULETKUT", select: false, id: 416, category: "JARRUT" },
    { name: "VASEN KÄSIJARRUVAIJERI", select: false, id: 417, category: "JARRUT" },
    { name: "OIKEA KÄSIJARRUVAIJERI", select: false, id: 418, category: "JARRUT" },
    { name: "VASEN ETU ABS-ANTURI", select: false, id: 419, category: "JARRUT" },
    { name: "OIKEA ETU ABS-ANTURI", select: false, id: 420, category: "JARRUT" },
    { name: "VASEN TAKA ABS-ANTURI", select: false, id: 421, category: "JARRUT" },
    { name: "OIKEA TAKA ABS-ANTURI", select: false, id: 422, category: "JARRUT" },
    { name: "JARRURUMMUT", select: false, id: 423, category: "JARRUT" },
    { name: "JARRUKENKÄSARJA", select: false, id: 424, category: "JARRUT" },
    //JÄÄHDYTYS/ILMASTOINTI
    { name: "KOMPRESSORI", select: false, id: 501, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "LAUHDUTIN", select: false, id: 502, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "KUIVAIN", select: false, id: 503, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "HÖYRYSTIN", select: false, id: 504, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "JÄÄHDYTIN", select: false, id: 505, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "PUHALTIMENMOOTTORI", select: false, id: 506, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "ESIVASTUS PUHALTIMENMOOTTORI", select: false, id: 507, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "VESIPUMPPU", select: false, id: 508, category: "JÄÄHDYTYS/ILMASTOINTI" },
    { name: "TERMOSTAATTI", select: false, id: 509, category: "JÄÄHDYTYS/ILMASTOINTI" },
    //KORI
    { name: "PYYHKIJÄNSULAT TUULILASI", select: false, id: 601, category: "KORI" },
    { name: "VASEN PYYHKIMENVARSI ", select: false, id: 602, category: "KORI" },
    { name: "OIKEA PYYHKIMENVARSI ", select: false, id: 603, category: "KORI" },
    { name: "PYYHKIJÄNVIVUSTO", select: false, id: 604, category: "KORI" },
    { name: "PYYHKIMENMOOTTORI TUULIASI", select: false, id: 605, category: "KORI" },
    { name: "PYYHKIJÄNSULKA TAKALASI", select: false, id: 606, category: "KORI" },
    { name: "PYYHKIMENVARSI TAKALASI", select: false, id: 607, category: "KORI" },
    { name: "PYYHKIMENMOOTTORI TAKALASI", select: false, id: 608, category: "KORI" },
    { name: "PESUNESTEPUMPPU", select: false, id: 609, category: "KORI" },
    { name: "ETÄISYYSTUNNISTIN", select: false, id: 610, category: "KORI" },
    //MOOTTORI
    { name: "JAKOHIHNASARJA", select: false, id: 701, category: "MOOTTORI" },
    { name: "JAKOKETJUSARJA", select: false, id: 702, category: "MOOTTORI" },
    { name: "KIRISTIN HAMMASHIHNA", select: false, id: 703, category: "MOOTTORI" },
    { name: "OHJAINRULLA HAMMASHIHNA", select: false, id: 704, category: "MOOTTORI" },
    { name: "VESIPUMPPU", select: false, id: 705, category: "MOOTTORI" },
    { name: "MONIURAHIHNA APULAITE", select: false, id: 706, category: "MOOTTORI" },
    { name: "KIRISTINVARSI APULAITE", select: false, id: 707, category: "MOOTTORI" },
    { name: "OHJAINRULLA APULAITE", select: false, id: 708, category: "MOOTTORI" },
    { name: "HIHNAPYÖRÄ KAMPIAKSELI", select: false, id: 709, category: "MOOTTORI" },
    { name: "EGR-VENTTIILI", select: false, id: 710, category: "MOOTTORI" },
    //PAKOPUTKISTO
    { name: "KATALYSAATTORI", select: false, id: 801, category: "PAKOPUTKISTO" },
    { name: "VÄLIPUTKI", select: false, id: 802, category: "PAKOPUTKISTO" },
    { name: "KESKIVAIMENNIN", select: false, id: 803, category: "PAKOPUTKISTO" },
    { name: "TAKAVAIMENNIN", select: false, id: 804, category: "PAKOPUTKISTO" },
    { name: "JOUSTOPUTKI", select: false, id: 805, category: "PAKOPUTKISTO" },
    { name: "HOLKKI", select: false, id: 806, category: "PAKOPUTKISTO" },
    { name: "KANNATINKUMIT", select: false, id: 807, category: "PAKOPUTKISTO" },
    { name: "KLEMMARIT", select: false, id: 808, category: "PAKOPUTKISTO" },
    { name: "PULTIT", select: false, id: 809, category: "PAKOPUTKISTO" },
    { name: "JOUSET", select: false, id: 810, category: "PAKOPUTKISTO" },
    { name: "PAKOPUTKEN TIIVISTE", select: false, id: 811, category: "PAKOPUTKISTO" },
    //SÄHKÖ
    { name: "AKKU", select: false, id: 901, category: "SÄHKÖ" },
    { name: "LATURI", select: false, id: 902, category: "SÄHKÖ" },
    { name: "STARTTI", select: false, id: 903, category: "SÄHKÖ" },
    { name: "HEHKUTULPPA", select: false, id: 904, category: "SÄHKÖ" },
    { name: "HEHKUTULPAT", select: false, id: 905, category: "SÄHKÖ" },
    { name: "SYTYTYSPUOLA", select: false, id: 906, category: "SÄHKÖ" },
    { name: "SYTYTYSPUOLAT", select: false, id: 907, category: "SÄHKÖ" },
    //VOIMANSIIRTO
    { name: "KYTKINPAKETTI", select: false, id: 1001, category: "VOIMANSIIRTO" },
    { name: "PAINELAAKERI KYTKIN", select: false, id: 1002, category: "VOIMANSIIRTO" },
    { name: "PÄÄSYLINTERI KYTKIN", select: false, id: 1003, category: "VOIMANSIIRTO" },
    { name: "TYÖSYLINTERI KYTKIN", select: false, id: 1004, category: "VOIMANSIIRTO" },
    { name: "IRROITUSMEKANISMI KYTKIN", select: false, id: 1005, category: "VOIMANSIIRTO" },
    { name: "VAUHTIPYÖRÄ", select: false, id: 1006, category: "VOIMANSIIRTO" },
    { name: "2-MASSAVAUHTIPYÖRÄ", select: false, id: 1007, category: "VOIMANSIIRTO" },
    { name: "AKSELITIIVISTE", select: false, id: 1008, category: "VOIMANSIIRTO" },
    { name: "VASEN ETU VETOAKSELI", select: false, id: 1009, category: "VOIMANSIIRTO" },
    { name: "OIKEA ETU VETOAKSELI", select: false, id: 1010, category: "VOIMANSIIRTO" },
    { name: "VETONIVELSARJA ETEEN", select: false, id: 1011, category: "VOIMANSIIRTO" },
    { name: "SUOJAKUMISARJA ETEEN VAIHTEISTON PUOLI", select: false, id: 1012, category: "VOIMANSIIRTO" },
    { name: "SUOJAKUMISARJA ETEEN PYÖRÄN PUOLI", select: false, id: 1013, category: "VOIMANSIIRTO" },
    { name: "VASEN TAKA VETOAKSELI", select: false, id: 1014, category: "VOIMANSIIRTO" },
    { name: "OIKEA TAKA VETOAKSELI", select: false, id: 1015, category: "VOIMANSIIRTO" },
    { name: "VETONIVELSARJA TAAKSE", select: false, id: 1016, category: "VOIMANSIIRTO" },
    { name: "SUOJAKUMISARJA TAAKSE VAIHTEISTON PUOLI", select: false, id: 1017, category: "VOIMANSIIRTO" },
    { name: "SUOJAKUMISARJA TAAKSE PYÖRÄN PUOLI", select: false, id: 1018, category: "VOIMANSIIRTO" },

]

