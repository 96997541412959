import React, { useContext, useState, useEffect } from 'react'
import { db } from '../firebase'
import { useAuth } from "../contexts/AuthContext";
import { createUsername } from '../functions/Functions'

const OrderContext = React.createContext();

export function useOrder() {
    return useContext(OrderContext)
}

export function OrderProvider({ children }) {
    const { currentUser } = useAuth()
    const [buttonState, setButtonState] = useState([])
    const [customer, setCustomer] = useState('')
    const [customerNumber, setCustomerNumber] = useState('')
    const [licenseInputValue, setLicenseInputValue] = useState('')
    const [parts, setParts] = useState('')
    const [getParts, setGetParts] = useState([])
    const [partList, setPartList] = useState([])
    const [selectButton, setSelectButton] = useState([])
    const [getOrders, setGetOrders] = useState([])
    const [allOrders, setAllOrders] = useState([])

    useEffect(() => {
        if(currentUser)
        return db.collection('parts').onSnapshot(snapshot => {
            const partData = [];
            snapshot.forEach(doc => partData.push(({ ...doc.data(), id: doc.id })))
            setGetParts(partData);
        }, (error) => {
        })
    }, [currentUser]);

    const currentDate = new Date().setHours(0,0,0,0)

    useEffect(() => {
        if(currentUser)
        return db.collection('orders').where('creator', '==', createUsername(currentUser.email))
            .where('createdAt', '>', currentDate)
            .orderBy('createdAt','desc')
            .onSnapshot(snapshot => {
                const orderData = [];
                snapshot.forEach(doc => {
                orderData.push(({ ...doc.data(), id: doc.id }))})
                setGetOrders(orderData);
        }, (error) => {
            console.log(error.message)
        })
    }, [currentUser, currentDate]);

    //All order data

    useEffect(() => {
        if(currentUser)
        return db.collection('orders')
            .orderBy('createdAt','desc')
            .onSnapshot(snapshot => {
                const allOrderData = [];
                snapshot.forEach(doc => {
                allOrderData.push(({ ...doc.data(), id: doc.id }))})
                setAllOrders(allOrderData);
        }, (error) => {
            console.log(error.message)
        })
    }, [currentUser]);
    
const value = {
    buttonState,
    setButtonState,
    licenseInputValue,
    setLicenseInputValue,
    customer,
    setCustomer,
    customerNumber, 
    setCustomerNumber,
    parts,
    setParts,
    getParts,
    setGetParts,
    partList,
    setPartList,
    selectButton, 
    setSelectButton,
    getOrders,
    setGetOrders,
    allOrders,
    setAllOrders
}
    return (
        <OrderContext.Provider value={value}>
            { children }
        </OrderContext.Provider>
    )
}
