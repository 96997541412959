import Login from '../components/Login/Login';
import Dashboard from "./Dashboard";
import { AuthProvider } from '../contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { OrderProvider } from '../contexts/OrderContext';
import './App.css'
import { CustomerProvider } from '../contexts/CustomerContext';
import AllOrders from './AllOrders/AllOrders';

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <CustomerProvider>
            <OrderProvider>
              <Switch>
                <PrivateRoute exact path='/' component={Dashboard} />
                <Route path='/login' component={Login} />
                <PrivateRoute path='/allorders' component={AllOrders} />
              </Switch>
            </OrderProvider>
          </CustomerProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
