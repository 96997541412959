import React, { useState, useEffect } from 'react'
import Navication from '../Navbar/Navbar'
import { useOrder } from '../../contexts/OrderContext'
import './AllOrders.css'
import Orders from '../Orders/Orders'

export default function AllOrders() {
    const { allOrders } = useOrder()
    const [ search, setSearch] = useState('')
    const [ data, setData ] = useState(allOrders)

    useEffect(() => {
        setData(allOrders)
    }, [allOrders])

    const excludeColumns = [
      "completed",
      "createdAt",
      "customerNumber",
      "date",
      "id",
      "quickPicks",
      "writedParts"
    ]
    const handleChange = value => {
        setSearch(value);
        filterData(value);
      };
    const handleClear = () => {
        setSearch('');
        filterData('');
      };

    const filterData = (value) => {
      const upperCaseValue = value.toUpperCase().trim();
        if (upperCaseValue === "") setData(allOrders);
        else {
          const filteredData = allOrders.filter(item => {
            return Object.keys(item).some(key =>
              excludeColumns.includes(key) ? false : item[key].toString().toUpperCase().includes(upperCaseValue)
            );
          });
          setData(filteredData);
        }
      }
    return (
        <div>
            <Navication />
            <div className="input-container">
            <div className="input-search">
                    <input
                    autoComplete="off"
                    required
                    value={search} 
                    type="text" 
                    id="search" 
                    placeholder="HAE..."
                    onChange={e => handleChange(e.target.value)}></input>
                </div>
                <button className="copy-button clear" type="button" onClick={handleClear}>TYHJENNÄ</button>
            </div>
            <div className="allorderscount"><h3>TILAUKSIA YHTEENSÄ: {allOrders.length}</h3></div>
              <div className="container-grid">
                {data.length ? data.map(order => {
                return (
                  <Orders order={order} key={order.id} />
                  )
                }): <h3 className="no-orders">EI TILAUKSIA</h3>}
              </div>
            </div>
    )
}
