import { useOrder } from '../../contexts/OrderContext'
import './OrderPreview.css'

export default function OrderPreview() {
    const { licenseInputValue } = useOrder()
    const { customer } = useOrder()
    const { buttonState } = useOrder()
    const { partList, setPartList } = useOrder()

    const selectParts = buttonState.filter(button => button.select === true)

    const removeItem = (removed) => {
        setPartList(partList.filter(part => part !== removed))
    }

    return (
        <>
        <div className="order-preview-row">
            <h2 className="order-preview-license">
                {licenseInputValue}
            </h2>
        </div>
        <div className="order-preview-row">
            <h3 className="order-preview-customer">
                {customer}
            </h3>
        </div>
            {selectParts.map(spart => {
                return (
                    <div className="row" key={spart.id}>
                        <p className="order-preview-parts">- {spart.name}</p>
                    </div>
                )}
            )}
            {partList.map((part, index) => {
                return (
                    <div onClick={() => removeItem(part)} className="row-list" key={index}>
                        <p className="order-preview-parts">- {part}</p>
                    </div>
                )}
            )}
        </>
    )
}