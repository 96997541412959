import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useRef, useEffect, useState} from 'react'
import { useOrder } from '../../contexts/OrderContext'
import { useAuth } from '../../contexts/AuthContext'
import { createUsername } from '../../functions/Functions'
import { useCustomer } from '../../contexts/CustomerContext'
import firebase from '../../firebase'
import 'firebase/firestore'
import './OrdersForm.css'
import  Accordion  from './Accordion'
import { quickParts, category } from './Parts'

export default function OrdersForm() {
    const { currentUser } = useAuth()
    const { licenseInputValue, setLicenseInputValue } = useOrder('')
    const { customer, setCustomer} = useOrder('')
    const inputRef = useRef()
    const { buttonState, setButtonState } = useOrder()
    const { getCustomers } = useCustomer()
    const { getParts } = useOrder()
    const { parts, setParts } = useOrder()
    const { partList, setPartList } = useOrder()
    const { setSelectButton } = useOrder()
    //const [selectAll, setSelectAll] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const focusedPartInput = useRef()
    const customerNumberRef = useRef()
    useEffect(() => {
        setSelectButton(category.map(data => {
            return {
                category: data.category
            }
        }));
    }, [setSelectButton])
    //filter parts array
    const selectedParts = buttonState.filter(button => button.select === true)

    useEffect(() => {
        setButtonState(quickParts.map(data => {
            return {
                name: data.name,
                id: data.id,
                select: data.select,
                category: data.category
            }
        }));
    }, [setButtonState])

    function addHyphen() {
        inputRef.current.addEventListener("keyup", (e) => {
            if (e.key === "Backspace" || e.key === "Delete") return;
            if (e.target.value.length === 3) {
                return inputRef.current.value = inputRef.current.value.toUpperCase() + "-";
            }
        })
        setLicenseInputValue(inputRef.current.value.toUpperCase())
    }  

    const handlePartsInput = (e) => {
        if(e.target.value !== '')
        if (e.key === 'Enter') {
            setParts(e.target.value.toUpperCase())
            setPartList(partList => [...partList, parts])
            setParts('')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(focusedPartInput.current === document.activeElement) {
        } else {
            try {
                setIsLoading(true);
                const db = firebase.firestore();
                await db.collection('orders').add({
                    licensePlate: licenseInputValue,
                    customerName: customer,
                    customerNumber: customerNumberRef.current.innerHTML,
                    createdAt: new Date().getTime(),
                    date: new Date().toLocaleString('fi').toUpperCase(),
                    creator: createUsername(currentUser.email),
                    quickPicks: selectedParts.map(part => {return part.name}),
                    writedParts: partList,
                    completed: false
                })
                setIsLoading(false);
                setLicenseInputValue('')
                setPartList([]);
                setButtonState(quickParts.map((d) => {
                    d.select = false
                    return d
                }))
                //Tilasus lisätty ilmoitus tulis tähän
            } catch (error) {
                setIsLoading(false);
            }
        }
        
    }

    return (
            <div className="order-form-card">
                <div></div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <input
                        type="text"
                        className="license-input"
                        placeholder="REK-NUM"
                        maxLength="7"
                        required
                        autoFocus
                        value={licenseInputValue}
                        ref={inputRef}
                        onChange={addHyphen} />
                    <CopyToClipboard text={licenseInputValue}>
                        <button className="copy-button" type="button">KOPIOI</button>
                    </CopyToClipboard>
                    <button 
                        className="addorder-button" 
                        type="submit"
                        disabled={isLoading}
                        >{isLoading ? 'LATAA...' : 'LISÄÄ TILAUS'}</button>
                </div>
                <div className="row">
                    <input
                        autoComplete="off"
                        className="customer-input"
                        placeholder="ASIAKAS"
                        required
                        value={customer}
                        id="customer-input"
                        list="customer-data"
                        onChange={e => setCustomer(e.target.value.toUpperCase())}/>
                        <datalist id="customer-data">
                            {getCustomers ? getCustomers.map(cust => (<option ref={customerNumberRef} value={cust.customerName} key={cust.id}>{cust.customerNumber}</option>)) : null}
                        </datalist>

                        <button className="copy-button" type="button" onClick={e => setCustomer(e.target.value = '')}>TYHJENNÄ</button>
                </div>
                <div className="row">
                    <input
                        autoComplete="off"
                        className="parts-input"
                        placeholder="KIRJOITA"
                        id="parts-input"
                        list="parts-data"
                        ref={focusedPartInput}
                        value={parts}
                        onKeyPress={handlePartsInput}
                        onChange={e => setParts(e.target.value.toUpperCase())}
                         />
                        <datalist id="parts-data">
                            {getParts ? getParts.map(part => (<option value={part.name} key={part.id}></option>)) : null}
                        </datalist>
                </div>
                <div className="row">
                    {category.map(categoryData => {
                        return (
                            <Accordion key={categoryData.id} title={categoryData.category} contentRef={quickParts.map(data => {
                                if(categoryData.category === data.category) {
                                return (
                                    <div className="row" key={data.id}>
                                        <label>
                                            <input
                                                key={data.id}
                                                type="checkbox"
                                                name={data.name}
                                                checked={data.select}
                                                onChange={(event => {
                                                    let checked = event.currentTarget.checked
                                                    setButtonState(quickParts.map(d => {
                                                        if (data.id === d.id) {
                                                            d.select = checked
                                                        }
                                                        return d
                                                    }))
                                                })} />
                                            <span>{data.name}</span>
                                        </label>
                                    </div>)}
                                    return null
                            })}></Accordion>
                        )
                    })}
                </div>
            </form>
{/*             {<input type="checkbox"
                name="VALITSE KAIKKI"
                checked={selectAll}
                onChange={event => {
                    setSelectAll(event.currentTarget.checked);
                    let checked = event.currentTarget.checked
                    setButtonState(quickParts.map((d) => {
                        d.select = checked
                        return d
                    }))
                }} />} */}
            </div>
    )
}