import './Grid.css'
import OrdersForm from '../OrdersForm/OrdersForm'
import OrderPreview from '../OrderPreview/OrderPreview'
import Orders from '../Orders/Orders'
import { useOrder } from '../../contexts/OrderContext'

export default function Grid() {
  const {getOrders} = useOrder()

  return (
    <div className="grid-container">
      <div className="grid-item orderform">
        <OrdersForm />
      </div>
      <div className="grid-item orderpreview">
        <div className="card-inside-preview">
          <OrderPreview />
        </div>
        <div className="card-inside">{getOrders.length ? getOrders.map(order => {
          return (
            <Orders order={order} key={order.id} />
            )
          }): <h3>EI TILAUKSIA</h3>}
        </div>
      </div>
        
    </div>
  )
}