import { useRef, useState, useEffect } from 'react'
import './Accordion.css'

export default function Accordion(props) {
    const [active, setActive] = useState(false)
    const [cssActive, setCssActive] = useState("");
    const contentRef = useRef(null)
  
    useEffect(() => {
      contentRef.current.style.maxHeight = active ? `${contentRef.current.scrollHeight}px` : '0px'
    }, [contentRef, active])
  
    const toogleActive = () => {
      setActive(!active)
      setCssActive(active ? "" : "active")
    }

    return (
        <div className="accordion-section">
            <button type="button" className={`accordion ${cssActive}`} onClick={toogleActive}>
                <p className="accordion-title">{props.title}</p>
            </button>
            <div ref={contentRef} className="accordion-content">
              <div className="row">
                    {props.contentRef}
              </div>
            </div>
        </div>
    )
}
